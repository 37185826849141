import "@aws-amplify/ui/dist/style.css"
import React from "react"
import { render } from "react-dom"
import { Auth } from "@aws-amplify/auth"
import { Hub } from "@aws-amplify/core"
import config from "./config"
import { useAuthState } from "./useAuthState"

const {
  userPoolId,
  userPoolWebClientId,
  oauthConfig,
  cookieStorageConfig,
} = config

Auth.configure({
  region: "ap-northeast-1",
  userPoolId,
  userPoolWebClientId,
  cookieStorage: {
    domain: cookieStorageConfig.domain,
    path: "/",
    expires: 365,
    secure: cookieStorageConfig.secure,
  },
  authenticationFlowType: "USER_PASSWORD_AUTH",
  oauth: {
    domain: oauthConfig.domain,
    scope: ["openid"],
    redirectSignIn: oauthConfig.redirectSignIn,
    redirectSignOut: oauthConfig.redirectSignOut,
    responseType: "code",
  },
})

const App = () => {
  const authState = useAuthState(Auth, Hub)

  switch (authState) {
    case "unknown":
      return <p>wait...</p>
    case "not_yet": {
      const redirectTo =
        new URLSearchParams(location.search).get("redirectTo") ??
        "" + (location.hash ?? "")

      // 行儀の悪いコードを書いてしまった
      Auth.federatedSignIn({
        customProvider: "AzureAD",
        customState: JSON.stringify({ redirectTo }),
      })
      return null
    }
    case "done": {
      const redirectTo = new URLSearchParams(location.search).get("redirectTo")
      if (redirectTo) {
        location.assign(redirectTo + (location.hash ?? ""))
        return null
      }

      // FIXME: 切り出してイケてるUIに
      return (
        <>
          <ul>
            <li>
              <a href="./security/">セキュリティ</a>
            </li>
            <li>
              <a href="./product-develop/">製品開発本部</a>
            </li>
            <li>
              <a href="./sample/">サンプル</a>
            </li>
            <li>
              <a href="./momo-simulator/">MOMOシミュレーター</a>
            </li>
            <li>
              <a href="./restie-storybook/main/">RESTIEストーリーブック</a>
            </li>
          </ul>
          <div>
            <button onClick={() => Auth.signOut()}>Sign Out</button>
          </div>
        </>
      )
    }
  }
}

render(<App />, document.getElementById("root"))
