const isDev = process.env.NODE_ENV === "development"

const config = {
  userPoolId: "ap-northeast-1_UdrDbksc1",
  userPoolWebClientId: "3pc9t29jkeclmqbf87pq9ldoq2",
  oauthConfig: {
    domain: "cureapp-internal-site.auth.ap-northeast-1.amazoncognito.com",
    redirectSignIn: "https://site.internal.cureapp.co.jp/",
    redirectSignOut: "https://site.internal.cureapp.co.jp/",
  },
  cookieStorageConfig: {
    domain: "site.internal.cureapp.co.jp",
    secure: true,
  },
}

const configDev: typeof config = {
  userPoolId: "ap-northeast-1_t63jAIBWq",
  userPoolWebClientId: "5750kvn4kb3hi96p0qv0mlj7ra",
  oauthConfig: {
    domain: "dev-cureapp-internal-site.auth.ap-northeast-1.amazoncognito.com",
    redirectSignIn: "http://localhost:1234/",
    redirectSignOut: "http://localhost:1234/",
  },
  cookieStorageConfig: {
    domain: "localhost",
    secure: false,
  },
}

export default isDev ? configDev : config
