import { useEffect, useState } from "react"
import { Auth, Hub } from "aws-amplify"

type AuthState = "not_yet" | "done" | "unknown"

export const useAuthState = (_Auth: typeof Auth, _Hub: typeof Hub) => {
  const [authState, setAuthState] = useState<AuthState>("unknown")
  useEffect(() => {
    if (location.search.startsWith("?code=")) {
      // ID Provider のログイン画面から帰ってきた場合は Amplify Hub を使って
      // token生成に成功したかどうかを監視する
      _Hub.listen("auth", (data) => {
        switch (data.payload.event) {
          case "signIn":
            setAuthState("done")
            break
          case "signIn_failure":
            setAuthState("not_yet")
            break
          case "customOAuthState":
            const customState = JSON.parse(
              decodeURIComponent(data.payload.data),
            )
            if (customState.redirectTo) {
              location.assign(customState.redirectTo)
              break
            }
            break
          default:
            break
        }
      })
    } else {
      // 普通に開かれた場合は Amplify Auth を使って
      // cookie にtokenが埋まってないか確認する。
      _Auth.currentAuthenticatedUser().then(
        () => {
          setAuthState("done")
        },
        (error: any) => {
          console.debug(error)
          setAuthState("not_yet")
        },
      )
    }
  }, [])

  return authState
}
